<template>
  <header :class="$style.header">
    <div :class="$style.header_inner">
      <nav :class="$style.nav_pc">
        <h1 :class="$style.h1_logo" alt="Tilon">
          <router-link to="/">
            <img src="../../assets/images/logo_theother.space.png" />
          </router-link>
        </h1>
        <div :class="$style.menu">
          <img src="../../assets/images/burger.png" />
        </div>
      </nav>
    </div>
  </header>
</template>

<script>
export default {};
</script>

<style module>
/* PC */
.header {
  position: fixed;
  margin: 0 auto;
  top: 0;
  width: 100%;
  max-width: 1920px;
  padding: 0 10rem;
  height: 4.938rem;
  box-sizing: border-box;
  z-index: 3;
  /* 블러효과 */
  /*background: rgba(255, 255, 255, 0.25);*/
  /*backdrop-filter: blur(0px);*/
  /*-webkit-backdrop-filter: blur(0px);*/
  /*border-radius: 8px;*/
  /*border-bottom: 1px solid #4c4c4d;*/
  /*box-shadow: 0 8px 32px 0 rgba(31, 38, 135, 0.37);*/
  /*border: 1px solid rgba(255, 255, 255, 0.18);*/
}

.header_inner {
  display: flex;
  height: 4.688rem;
}

.nav_pc {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
}

/* Tablet */
@media screen and (max-width: 1280px) {
  .header {
    padding: 0 6.25rem;
  }
}

/* Mobile */
@media screen and (max-width: 765px) {
  .header {
    padding: 0 2rem;
  }
}
</style>
