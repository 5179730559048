<template>
  <div id="app" class="app">
    <TheHeader />
    <router-view></router-view>
  </div>
</template>

<script>
import TheHeader from "@/components/common/TheHeader";
export default {
  name: "App",
  components: { TheHeader },
};
</script>

<style>
@import "../src/assets/css/reset.css";
.app {
  display: flex;
}
</style>
